<script setup lang='ts'>
import { useNote } from '@Composables/note';

const props = defineProps({
  title: {
    required: true,
    type: String
  },
  answer: {
    default: false,
    type: Boolean
  }
})

const { setTitle } = useNote();
</script>

<template>
  <p class="mb-0">
    <span v-if="answer">{{ $t("notes.mailbox.answer") }}</span>
    {{ setTitle(title) }}
  </p>
</template>
