import { storeToRefs } from 'pinia';

import { useUserAuthStore } from '~coreStores/user-auth.store';

import notes from '@Enums/notesTypes';
import type { INote } from '@Models/Note/note.model';

const enumNotes = notes;

export const useNote = () => {
  const userAuthStore = useUserAuthStore();
  const { getBailiffOfficeUserData } = storeToRefs(userAuthStore);

  const { $i18n } = useNuxtApp();

  const setTitle: any = (title: string) => {
    const setTitle: { [key: string]: string } = {
      [enumNotes.DOCUMENTS_REQUEST]: $i18n.t('notes.messagesTitle.other'),
      [enumNotes.INFO_EXCLUSION]: $i18n.t('notes.messagesTitle.excluded'),
      [enumNotes.POWER_OF_ATTORNEY_REQUEST]: $i18n.t('notes.messagesTitle.proxy'),
      [enumNotes.INFO_LOWERED_MARGIN]: $i18n.t('notes.messagesTitle.margin'),
      [enumNotes.INFO_KNOCK_DOWN_REFUSED]: $i18n.t('notes.messagesTitle.knockDownRefused'),
      [enumNotes.INFO_ITEM_PAYMENT_REFUSED]: $i18n.t('notes.messagesTitle.itemPaymentRefused'),
      [enumNotes.INFO_MARGIN_AUTOMAT]: $i18n.t('notes.messagesTitle.infoMarginAutomat'),
      [enumNotes.INFO_PRINCIPAL_ORIGINAL]: $i18n.t('notes.messagesTitle.infoPrincipalOriginal'),
      [enumNotes.INFO_DOCUMENT_ORIGINAL]: $i18n.t('notes.messagesTitle.infoDocumentOriginal'),
      [enumNotes.INFO_MARGIN_BAILIFF]: $i18n.t('notes.messagesTitle.infoMarginAutomat'),
      [enumNotes.INFO_REFUSE_KNOCKED]: $i18n.t('notes.messagesTitle.infoRefuseKnocked'),
      [enumNotes.INFO_COMPLAINT_BAILIFF_DECISION]: $i18n.t('notes.messagesTitle.infoComplaintBailiffDecision'),
      [enumNotes.INFO_COMPLAINT_COURT_DECISION]: $i18n.t('notes.messagesTitle.infoComplaintCourtDecision'),
      [enumNotes.INFO_WAIVER_PERIOD]: $i18n.t('notes.messagesTitle.infoWaiverPeriod'),
      [enumNotes.INFO_WAIVER_REQUEST]: $i18n.t('notes.messagesTitle.infoWaiverRequest'),
      [enumNotes.INFO_WAIVER_DECISION]: $i18n.t('notes.messagesTitle.infoWaiverConfirm'),
      [enumNotes.INFO_KNOCK_DOWN_WINNER]: $i18n.t('notes.messagesTitle.infoKnockDownWinner'),
      [enumNotes.INFO_KNOCK_DOWN_BIDDER]: $i18n.t('notes.messagesTitle.infoKnockDownBidder')
    };
    return setTitle[title];
  };

  const setCallLink = (itemId: string, messageId: string, text = null) => {
    const destination = getBailiffOfficeUserData.value ? 'bailiff' : 'user';
    const linkText = text || $i18n.t('notes.requestText');
    return `<a href='/${destination}/items-details/${itemId}/note/${messageId}' class='color--primary'>${linkText}</a>`;
  };

  async function checkNoteHasBeenRead(note: INote, noteStore: any) {
    if (note.readDate === null) {
      await saveReadNote(note.bidderMessageId.toString(), noteStore);
    }
    if (note.responsesCount >= 1) {
      if (note.responses) {
        const responses = note.responses.filter((response) => {
          return response.readDate === null;
        });
        if (responses.length >= 1) {
          await saveReadNote(responses[0].bidderMessageId.toString(), noteStore);
        }
      }
    }
  }

  async function saveReadNote(id: string, noteStore: any) {
    const data = {
      id
    };
    await noteStore.markNoteAsRead(data);
  }

  return {
    setTitle,
    setCallLink,
    checkNoteHasBeenRead
  };
};
