import { defineStore } from 'pinia';
import type { INote } from '@Models/Note/note.model';
import type { IResponse } from '@Models/response.model';
import notesTypes from '@Enums/notesTypes';
import {
  fetchNotesByItemId,
  fetchSingleNoteById,
  saveNoteAsRead,
  saveAnswer
} from '@Services/note.service';

export const useNoteStore = defineStore('note', {
  state: () => ({
    notes: [] as INote[],
    principalNotes: [] as INote[],
    documentNotes: [] as INote[],
    marginNotes: [] as INote[],
    note: {
      bailiffName: '',
      base64Description: '',
      bidderMessageId: 0,
      dateCreated: new Date(),
      itemId: 0,
      registryUnitId: 0,
      requestType: '',
      userName: '',
      messageAuthor: '',
      parentMessageId: 0,
      readDate: new Date(),
      responsesCount: 0,
      responsesUnreadCount: 0,
      responses: [],
      answerRequired: false
    } as INote
  }),
  actions: {
    async fetchNoteById(noteId: number): Promise<IResponse<INote> | unknown> {
      const response: any = await fetchSingleNoteById(noteId);
      if (response?.ok) {
        this.note = ((response as IResponse<INote>).object);
        return response;
      }
      return { ok: false }
    },
    async fetchNotes(data: { id: string, registryUnitId?: string }) {
      const response = await fetchNotesByItemId(data);
      if (response) {
        this.notes = (response as INote[]).reverse();
        this.setPrincipalNotes(response as INote[]);
        this.setDocumentNotes(response as INote[]);
        this.setMarginNotes(response as INote[]);
      }
      return response;
    },
    setPrincipalNotes(newNotes: INote[]): void {
      let data: any = [];
      if (newNotes.length > 0) {
        data = newNotes.filter(
          (el) =>
            el.requestType === notesTypes.POWER_OF_ATTORNEY_REQUEST ||
            el.requestType === notesTypes.INFO_PRINCIPAL_ORIGINAL
        );
      }
      this.principalNotes = data;
    },
    setDocumentNotes(newNotes: INote[]): void {
      let data: any = [];
      if (newNotes.length > 0) {
        data = newNotes.filter(
          (el) =>
            el.requestType === notesTypes.DOCUMENTS_REQUEST ||
            el.requestType === notesTypes.INFO_DOCUMENT_ORIGINAL
        );
      }
      this.documentNotes = data;
    },
    setMarginNotes(newNotes: INote[]): void {
      let data: any = [];
      if (newNotes.length > 0) {
        data = newNotes.filter(
          (el) =>
            el.requestType === notesTypes.INFO_MARGIN_AUTOMAT ||
            el.requestType === notesTypes.INFO_MARGIN_BAILIFF
        );
      }
      this.marginNotes = data;
    },
    async markNoteAsRead(data: any): Promise<IResponse<INote> | unknown> {
      return await saveNoteAsRead(data.id);
    },
    async sendAnswer(data: {
      isBailiff: boolean,
      formData: any
    }): Promise<IResponse<INote> | unknown> {
      return await saveAnswer(data);
    }
  },
  getters: {
    getNotes: (state) => {
      return state.notes;
    },
    getSingleNote: (state): INote => {
      return state.note;
    },
    getPrincipalNotes(): INote[] {
      return this.principalNotes;
    },
    getDocumentNotes(): INote[] {
      return this.documentNotes;
    },
    getMarginNotes(): INote[] {
      return this.marginNotes;
    }
  }
})